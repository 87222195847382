
import { computed, defineComponent, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { MAX_PER_PAGE } from '@/apis';
import { formatLocalTime } from '@/utils/format-time';
import { useChannelCampaigns, useChannels } from '@/composables/api';

export default defineComponent({
  setup() {
    const channelId = useRoute().params.id as string;
    const page = ref(1);
    const pageSize = ref(30);

    const { data, isLoading, isFetching } = useChannelCampaigns({ channelId }, { page, pageSize });
    const { data: channels } = useChannels({ page: 1, pageSize: MAX_PER_PAGE });

    const channel = computed(() => channels.value?.data.find(item => item.id === Number(channelId)));

    return {
      page,
      data,
      channel,
      isLoading,
      isFetching,
      formatLocalTime
    };
  }
});
